import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { showModal } from 'shared/foreground/actions/modals';

import { discoverWisereadsModalId } from './Modals/AddWisereadsItemsModal';

export const WisereadsDiscovery: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    showModal({ id: discoverWisereadsModalId }, { userInteraction: 'unknown' });
    history.push('/library');
  }, [history]);

  return null;
};
